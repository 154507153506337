import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
const SettingsContext = createContext();

const SettingsProvider = ({ children }) => {
  const [setting, setSetting] = useState({
    type: "Auto",
    decimals: 6,
    zeroDisplay: "1.0E-12",
    length: "m",
    force: "N",
    moment: "N.m",
    modulus: "Pa",
    mass: "kg",
    angle: "deg",
  });

  setting.density = `${setting.mass}/${setting.length}\u00B3`;
  setting.specificWeight = `${setting.force}/${setting.length}\u00B3`;
  setting.square = `${setting.length}\u00B2`;
  setting.cube = `${setting.length}\u00B3`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios({
          method: "GET",
          url: `/api/userSettings`,
        });
        setSetting({ ...setting, ...data.settings });
      } catch (error) {
        if (error.status === 401) {
          router.push("/auth/login");
        }
      }
    };
    fetchData();
  }, []);

  return (
    <SettingsContext.Provider value={{ setting, setSetting }}>
      {children}
    </SettingsContext.Provider>
  );
};

const useSettings = () => useContext(SettingsContext);

export { SettingsProvider, useSettings };
