import React, { createContext, useContext, useState, useEffect } from "react";
import { useRouter } from "next/router";
import { getSession, useSession } from "next-auth/client";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { pathname, events } = useRouter();
  const [userSession, setUserSession] = useState(null);
  const [session, loading] = useSession();

  async function getUserSession() {
    try {
      const tSession = await getSession();
      if (!tSession) {
        setUserSession({ loggedIn: false });
      } else {
        setUserSession({ ...tSession, loggedIn: true });
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getUserSession();
  }, [pathname]);

  useEffect(() => {
    // Check that a new route is OK
    const handleRouteChange = (url) => {
      if (url !== "/auth/login" && userSession && !userSession.loggedIn) {
        debugger;
        window.location.href = "/auth/login";
      }
    };

    // Check that initial route is OK
    if (pathname !== "/auth/login" && userSession && !userSession.loggedIn) {
      window.location.href = "/auth/login";
    }

    // Monitor routes
    events.on("routeChangeStart", handleRouteChange);
    return () => {
      events.off("routeChangeStart", handleRouteChange);
    };
  }, [userSession]);

  return (
    <AuthContext.Provider value={{ user: userSession }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
