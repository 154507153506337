import React, { createContext, useContext, useState } from "react";
const UISettingsContext = createContext();

const UISettingsProvider = ({ children }) => {
  const [uiSettings, setUISettings] = useState({
    rightPaneOpen: false,
  });

  return (
    <UISettingsContext.Provider value={{ uiSettings, setUISettings }}>
      {children}
    </UISettingsContext.Provider>
  );
};

const useUISettings = () => useContext(UISettingsContext);

export { UISettingsProvider, useUISettings };
